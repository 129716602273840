import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './home/homepage/homepage.component';

const routes: Routes = [
  { path: 'inicio', component: HomepageComponent },
  {
    path: 'filetes',
    loadChildren: './filetes/filetes.module#FiletesModule'
  },
  {
    path: 'pescados',
    loadChildren: './pescados/pescados.module#PescadosModule'
  },
  {
    path: 'camarones',
    loadChildren: './camarones/camarones.module#CamaronesModule'
  },
  {
    path: 'almejas',
    loadChildren: './almejas/almejas.module#AlmejasModule'
  },
  {
    path: 'varios',
    loadChildren: './varios/varios.module#VariosModule'
  },
  {
    path: '',
    redirectTo: '/inicio',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
